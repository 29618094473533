.c-section {
    body[data-tmpl="home"] & {
        max-width: 350px;
    }
    &__heading {
        position: sticky;
        top: 0;
        padding-top: 1rem;
        margin-bottom: 0;
        padding-bottom: 1.3rem;
        z-index: 600;
        background: linear-gradient(rgba(255,255,255,1) 60%, rgba(255,255,255,0));
    }
    &__content.stick {
        @media (max-width: 900px) {
            
        }
    }
}