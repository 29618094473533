@import '~glightbox/dist/css/glightbox.min.css';

@import './utils/preflight.css';
@import './utils/helpers.scss';

@import './global.scss';
@import './vars.scss';
@import './birb.css';

@import './layouts/layouts.scss';

@import './components/header.scss';
@import './components/section.scss';

@import './pages/exhibition.scss';
@import './pages/home.scss';
@import './pages/error.scss';

@import './plugins/glightbox.scss';