.bg {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform: scale(.8);
    z-index: 1000;
    object-fit: contain;
    filter: blur(10px);

    transition: all .1s linear;

    &.is-visible {
        opacity: 1;
        filter: blur(0);
    }
}

#nlsub input {
    background-color: transparent;
    border-radius: 0;
}