html, body {
    margin: 0;
    padding: 0;
    position: relative;
}

html {
    font-size: 13px;
    line-height: 1.3em;
}
  
body {
    padding: var(--gutter) 0;
    font-family: sans-serif;
    font-weight: normal;
    color: black;
}

body::before {
    height: 3rem;
    content: "";
    width: 100%;
    position: fixed;
    top: 0;
    background: linear-gradient(rgba(255,255,255,1) 80%, rgba(255,255,255,0));
    z-index: 500;
}

h2 {
    margin-bottom: 1.3rem;
}

address {
    font-style: inherit;
}

a {
    color: black;
    text-decoration: none;

    &:hover {
        color: var(--pink);
    }

    & p {
        display: inline;
    }
}

.stretch {
    display: inline-block;
    transform: scale(2, .9);
    margin-left: .5em;
    margin-right: .5em;
}

.stick {
    position: sticky;
    top: var(--top);
    z-index: 590;
}

.no-top-blur {
    position: relative;
    z-index: 1000;
}

.indent {
    text-indent: 1rem;
}

#back-to-top {
    position: fixed;
    z-index: 9999;
    bottom: 1rem;
    left: 1rem;
    display: none;
    @media (max-width: 580px) {
        & {
            display: none!important;
        }
    }
}