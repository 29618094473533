body.glightbox-open {
    overflow: initial;
}

.glightbox-exhibition {

    // container
    z-index: 10000!important;

    .goverlay {
        background: rgba(255,255,255,1);
    }

    .ginner-container {
        width: 100%;
        height: 100vh;
        justify-content: flex-end;
        align-items: flex-start;
        // max-width: 100vh;
        margin: 0;

        @media(max-width: 580px) {
            justify-content: center;
        }
    }

    .gslide-media {
        justify-self: flex-end;
        justify-content: flex-end;
        align-items: flex-start;
        margin: unset;
        margin-left: 25vw;
        @media (max-width: 580px) {
            margin-left: 0;
        }
    }

    .gslide-image {
        img {
            padding: 1rem;
            max-height: calc(100vh - 4rem) !important;
            max-width: 100%;
        }
    }

    .gslide-description {
        padding: 1rem!important;
        position: absolute!important;
        top: 0!important;
        // width: calc(100vw - 100px)!important;
        max-width: unset!important;
        background: none!important;
        & .gslide-desc {
            color: inherit!important;
        }

        .gslide-desc {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: var(--gutter);

            .gslide-counter {
                width: 4rem;
                white-space: nowrap;
            }

            .gslide-description-text {
                grid-column: span 2;
                @media (hover: none) {
                    grid-column: span 3;
                }
            }
        }

    }

    .gbtn:focus:not(:focus-visible) {
        outline: 0 !important;
        box-shadow: none !important;
    }

    .gbtn {
        width: auto;
        height: auto;

        @media (hover: none) {
            display: none;
        }
    }

    .gnext,
    .gprev {
        position: fixed;
        top: 3rem;
        height: calc(100vh - 3rem);
        & svg {
            display: none;
        }
    }

    .gnext {
        width: 50vw;
        right: 0;
        cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" style="font-size: 13px;"><text font-family="Arial" y="15">Next</text></svg>'), auto;
    }

    .gprev {
        width: 50vw;
        left: 0;
        cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" style="font-size: 13px;"><text font-family="Arial" y="15">Prev</text></svg>'), auto;
    }

    .gclose {
        position: fixed;
        top: var(--gutter);
        right: var(--gutter);
        & svg {
            display: none;
        }
        &::after {
            content: "Close";
        }
    }
}

.glightbox-iframe {
    .goverlay {
        background: transparent;
    }
    .ginner-container {
        border: 1px solid;
    }
}