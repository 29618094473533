.p-exhibition {
    &__preview-images {
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: repeat(6, 1fr);
        margin-top: 1rem;
        @media (max-width: 580px) {
            grid-template-columns: repeat(3, 1fr);
        }

        & figure {
            margin-right: -2rem;
            margin-bottom: 0!important;
            cursor: pointer;
        }

        &__caption {
            margin-top: .5rem;
            margin-bottom: 1rem;
            text-align: center;
        }
    }

    &__text {
        padding-top: 1rem;
        margin-bottom: 3rem;
        max-width: 400px;
    }

    &__images {
        & [data-orientation="portrait"] {
            width: 66.6%;
        }

        &__figure {
            margin-right: -2rem;
            margin-bottom: 3rem;

            &[data-is-cover="1"] {
                grid-column: span 3;
                @media (max-width: 580px) {
                    grid-column: span 2;
                }
            }
        }

        &__container {
            height: 0;
            padding-bottom: var(--ratio);
        }

        &__caption {
            margin-top: .5rem;
            margin-bottom: 1rem;
        }
    }
}

.c-exhibition-header {
    max-width: 350px;
}