.input-sizer {
    display: inline-grid;
    vertical-align: top;
    align-items: center;
    position: relative;

    &::after,
    input {
        width: auto;
        min-width: 1em;
        grid-area: 1 / 2;
        font: inherit;
        padding: 0;
        margin: 0;
        resize: none;
        background: none;
        appearance: none;
        border: none;
    }

    &::after {
        content: attr(data-value) ' ';
        visibility: hidden;
        white-space: pre-wrap;
    }
}