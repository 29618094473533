.l-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;
    padding: 0 1rem;
    margin-right: 2rem;

    &__element {
        &--wide {
            grid-column: span 2;
            @media (max-width: 900px) {
                grid-column: span 1;
            }
        }
        &--off-by-1 {
            grid-column: 1 / span 2;
            @media (max-width: 900px) {
                grid-column: 2 / span 3;
            }
            @media (max-width: 580px) {
                grid-column: 1
            }
        }
        &--off-by-2 {
            grid-column: 3 / span 2;
            @media (max-width: 900px) {
                grid-column: 2 / span 1;
            }
            @media (max-width: 580px) {
                grid-column: 1
            }
        }
    }
}

main.l-grid {
    @media (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 580px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

header .l-grid {
    grid-gap: 1rem 3rem;
    @media (max-width: 580px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.l-textblock {

    & p {
        margin-bottom: 1rem;
        max-width: 400px;
    }
}

.l-onegridwidth {
    width: calc(25vw - 3rem);
    @media (max-width: 900px) {
        width: calc(50vw - 3rem);
    }
    @media (max-width: 580px) {
        width: auto;
    }
}

.l-ratio-box {
    height: 0;
    padding-bottom: var(--ratio);
    background-color: var(--transparent-pink);
}