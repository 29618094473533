.c-header {
    height: 200px;
}

.c-header__content {
    position: sticky;
    top: 1em;
    z-index: 600;
}

.c-header__item {
    position: sticky;
    top: var(--gutter);
}